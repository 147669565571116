@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin:0;
  padding:0;
}

#page, #root, html, body{
  height: 100%;
  width:100%;
  background:#000;

}

.wrap{
  max-width: 960px;
  margin: 0 auto;
}

body{
  font-family: "Titillium Web" !important;
  font-weight: 300 !important;
}


#background{
  background: url('/giphy.gif');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position:fixed;
  z-index:0;
  opacity: 0.15;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}


#content{
  position: relative;
  z-index:1;
  color: white !important;
}

#header{
  width:100%;
  margin-top:40px;
  display: inline-block;
}
#logo{
  background: url('/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 250px;
  height: 90px;
  z-index:2;
  margin: 0 auto;
  display: block;
}

#title{
  color: white;
  text-align: center;
  font-size: 62px !important;
  font-weight: bold;
  margin: 120px 0;
  font-family: "Comfortaa" !important;
}

.parent {
  position: relative;
  height: 100%;
}
.child {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.rainbow-head{
  background: rgb(207,92,120);
  background: -webkit-gradient(linear, left top, right top, from(rgba(207,92,120,0.4)), color-stop(35%, rgba(0,88,155,0.4)), color-stop(65%, rgba(0,160,176,0.4)), to(rgba(245,223,77,0.4)));
  background: -webkit-linear-gradient(left, rgba(207,92,120,0.4) 0%, rgba(0,88,155,0.4) 35%, rgba(0,160,176,0.4) 65%, rgba(245,223,77,0.4) 100%);
  background: linear-gradient(90deg, rgba(207,92,120,0.4) 0%, rgba(0,88,155,0.4) 35%, rgba(0,160,176,0.4) 65%, rgba(245,223,77,0.4) 100%);  
  padding: 45px 0;
  margin-top: 40px;
}

.rainbow-head-title{
  font-family: "Comfortaa" !important;
  font-size: 32px !important;
  color: white;
  font-weight: bold;
  display: inline-block;
  text-align:center;
  font-size: 30px;
  text-transform: uppercase;

}

#t1, #t2{
  width: 50%;
}

#t1{
  padding-right: 127px;
}


#t2{
  padding-left: 127px;
}

div[role="group"]{
  margin-bottom: 15px;
}

input, textarea, .input {
    background: #ffffff21 !important;
    border: 1px solid #fff3 !important;
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    color: white !important;
}

input:hover, textarea:hover, .input:hover{
  background: rgba(255,255,255,0.2) !important;
}


.form{
  width: 50%;
  display: inline-block;
}

#form1 .form-in{
  padding: 0 127px 0 0;

}

#form2 .form-in{
  padding: 0 0 0 127px;

}

.form-description{
  text-align: center;
  font-size: 16px;
  margin: 40px 0;
}

button{
  font-family: "Titillium Web";
}

#footer{
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Titillium Web";
  font-weight: bold;
}

#copyright{
  text-align: center;
  color: rgba(255,255,255,0.5)
}

.input-mask{
  overflow:hidden;
  height: 40px;
  position: relative;
  width: 100%;
}

.input-file{
  opacity:0;
  box-shadow: inset 0 0 0 1px red;
  width: 100%;
  height: 100%;
  font-size: 550px;
  position: absolute;
  z-index: 1;
  cursor:pointer !important;
}

.input-imitator{
  width: 100%;
  height: 40px;
  position: relative;
  z-index:0;
}

.input-imitator-label{
  padding-left:40px;
  padding-top:7px;
  color: rgba(255,255,255,0.4) !important;
}

.mobile{
  display:none;
}


.textloop{
  width: 250px;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .mobile{
    display:block !important;
  }
  .textloop{
    width: 135px;
    display: inline-block;
  }
  .wrap{
    width: 100%;
  }
  #logo{
    width: 160px;
  }
  #title{
    padding:0 20px;
    margin: 60px 0;
    font-size: 32px !important;
  }
  #title span{
    display: inline-block;
  }
  #content{
    width: 100%;
  }

  #rainbow-head{
    display:none;
  }

  #forms .form{
    display:block;
    clear:both;
    width: 100%;
  }
  #form1 .form-in,
  #form2 .form-in{
    padding: 0 20px;
  }
  .rainbow-head{
    padding: 20px 0 !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .chakra-modal__content-container{
    padding: 0 20px;
  }

  .chakra-modal__content-container .chakra-button{
    width: 100%;
  }

  .mobile .rainbow-head-title{
    text-align:center;
    width: 100%;
    font-size: 22px !important;
  }
}
